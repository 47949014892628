import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './assets/scss/main.scss';

import Home from './routes/base/home';

ReactDOM.render(
  	<React.StrictMode>
		<Router>
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>
				<Route exact path="*">
					<h1>404</h1>
				</Route>
			</Switch>
		</Router>
  	</React.StrictMode>,
  	document.getElementById('root')
);
